@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: rgb(61, 61, 61);
  background-image: url("Assets/beautiful-blue-stars-fill.jpg");
  background-repeat: repeat-y repeat-x;
  animation: slide 30s linear infinite;
}

@keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 100%;
  }
}